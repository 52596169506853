<template>
  <b-img
    class="logo"
    :src="logoSrc"
  />
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  computed: {
    logoSrc() {
      return process.env[`VUE_APP_LOGO_2_${this.$i18n.locale.toUpperCase()}`]
    },
  },
}
</script>

<style scoped>
.logo {
  width: 150px;
}
</style>
